import React from 'react';
import './styles.scss';
import { NavLink } from 'react-router-dom';

const NavItem = ({ setOpen, open, navText, linkTo, exact }) => {
  return (
    <NavLink
      to={linkTo}
      className='nav--inline__item nav--dropdown_item'
      activeClassName='item--active'
      exact={exact}
      onClick={() => setOpen(!open)}
    >
      {navText}
    </NavLink>
  );
};

export default NavItem;
