import React from 'react';

import './styles.scss';
import Button from '../Button/Button';
import Icons from '../Icon/Icons';

const About = () => {
  return (
    <div className='content about'>
      <div className='content--side-margin'>
        <div />
        <div>
          <h1>
            <span className='header__title'>Hello,</span>
            <span className='header__title'>
              I'm <span className='red-text'>Saraluk</span>
            </span>
            <span className='header__subtitle'>
              I design and build intuitive UX.
            </span>
          </h1>
          <div className='content__about'>
            I'm Saraluk Kaiwansakul, a frontend developer based in New York. I
            specialize in building seamless and responsive user experience on
            web and mobile using JavaScript technologies. Strong interest in
            UX/UI design and excels at using Photoshop and Illustrator.
          </div>
          <div className='buttons'>
            <Button
              buttonText='Download resume'
              download="Saraluk's Resume"
              link="/file/Saraluk's Resume.pdf"
              buttonStyle='btn--solid'
            />
            <Button
              buttonText='Get in touch'
              link='mailto:saralukkai@gmail.com'
              buttonStyle='btn--light'
            />
            <div />
          </div>
          <div className='icons--3'>
            <Icons />
            <div />
          </div>
        </div>
        <div />
      </div>
    </div>
  );
};

export default About;
