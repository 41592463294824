import React from 'react';

const HamburgerIcon = ({ open, setOpen }) => {
  return (
    <div
      className={`hamburger__icon ${open ? 'close' : ''}`}
      onClick={() => setOpen(!open)}
    >
      <div></div>
    </div>
  );
};

export default HamburgerIcon;
