const PROJECTS = {
  ResiHub: {
    id: 0,
    img: '/img/Resihub-mobile-app.png',
    githubLink: 'https://github.com/saraluk/resihub-react-native',
    viewLink: 'https://youtu.be/RkUHEJO2Uxo',
    techUsed: ['JavaScript', 'React Native'],
    description:
      'A mobile application serves as a rental building information hub allowing users to explore rental building in NYC area.',
  },
  FavDish: {
    id: 1,
    img: '/img/Favdish-mobile-app.png',
    githubLink: 'https://github.com/saraluk/ionic-favdish-mobile-app',
    viewLink: 'https://ionic-favdish-mobile-app.web.app',
    techUsed: ['TypeScript', 'Ionic', 'Angular'],
    description:
      'A mobile application allowing users to store their favorite dishes and able to refer back at later time.',
  },
  'Capsule News': {
    id: 2,
    img: '/img/Capsule-news-mobile-app.png',
    githubLink: 'https://github.com/saraluk/ionic-capsule-news-mobile-app',
    viewLink: 'unavailable',
    techUsed: ['TypeScript', 'Ionic', 'Angular'],
    description:
      'A mobile application built with Ionic Angular framework for serving daily news (fetching data from newsapi.org).',
  },
  Bicycle: {
    id: 3,
    img: '/img/Responsive-web-design.png',
    githubLink: 'https://github.com/saraluk/responsive-web-design',
    viewLink: 'https://saraluk-responsive-web-design.web.app',
    techUsed: ['HTML', 'CSS'],
    description:
      'A responsive website styled with CSS and CSS grid. It was designed to adapt the layout according to the screen sizes.',
  },
  "Bridget Riley's Canvas": {
    id: 4,
    img: '/img/Bridget-riley-art.png',
    githubLink: 'https://github.com/saraluk/canvas-device-motion',
    viewLink: 'https://canvas-device-motion.web.app',
    techUsed: ['HTML canvas', 'JavaScript'],
    description:
      "A Bridget Riley's art drawn using HTML canvas and JavaScript. The canvas responds to device motion and acceleration. (Try it on your phone !)",
  },
  'Zombie Land': {
    id: 5,
    img: '/img/Zombieland-2d-game.png',
    githubLink:
      'https://github.com/saraluk/game-programming-zombieland-platformer',
    viewLink: 'https://saraluk.itch.io/zombie-land-platformer',
    techUsed: ['C#', 'Unity'],
    description:
      'A simple 2D platform game developed using Unity game engine. A player must survive all 3 levels with limited lives to win.',
  },
  'Fire Rescue': {
    id: 6,
    img: '/img/Firerescue-3d-game.png',
    githubLink:
      'https://github.com/saraluk/game-programming-fire-rescue-3D-game',
    viewLink: 'https://saraluk.itch.io/fire-rescue',
    techUsed: ['C#', 'Unity'],
    description:
      'A simple 3D game developed using Unity game engine. A player must find and rescue people in limited time and lives to win',
  },
};

export default PROJECTS;
