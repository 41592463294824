import React from 'react';

import './styles.scss';

const Button = ({ link, download, buttonText, buttonStyle }) => {
  return (
    <a className={buttonStyle} href={link} download={download}>
      <div>{buttonText}</div>
    </a>
  );
};

export default Button;
