import React from 'react';

const ProjectNameTab = ({ projectList, currentTitle, setTitle }) => {
  return (
    <div className='project__tabs'>
      {projectList.map((title) => {
        return (
          <div
            onClick={() => setTitle(title)}
            className={`project__tab ${
              currentTitle === title ? 'active' : 'non-active'
            }`}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectNameTab;
