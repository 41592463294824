import React from 'react';
import './styles.scss';
import ProjectContainer from './ProjectsContainer';

const Works = () => {
  return (
    <div className='content works'>
      <div className='content--side-margin'>
        <div />
        <div>
          <h1 className='header__title'>What I have done</h1>
          <h2 className='header__subtitle--small'>
            Recent personal and academic projects e.g. mobile apps, websites,
            games.
          </h2>
          <ProjectContainer />
        </div>
        <div />
      </div>
    </div>
  );
};

export default Works;
