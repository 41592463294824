import React from 'react';
import './styles.scss';
import './../Icon/Icon';
import './../Icon/styles.scss';
import Icon from './../Icon/Icon';
import PROJECTS from './../../utils/projects';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

const ProjectDisplay = ({ title }) => {
  const project = PROJECTS[title];
  const { img, githubLink, viewLink, techUsed, description } = project;
  return (
    <div className='projects__display'>
      <img src={img} alt={title} />
      <div className='project__details'>
        <h1>{title}</h1>
        <div className='technologies'>
          {techUsed.map((item, index) => {
            if (index === techUsed.length - 1) {
              return <span>{`${item}`}</span>;
            }

            return <span>{`${item} , `}</span>;
          })}
        </div>
        <hr />
        <p>{description}</p>
        <div className='icons--2'>
          <Icon icon={faGithub} link={githubLink} />
          {viewLink === 'unavailable' ? (
            <Icon icon={faEyeSlash} />
          ) : (
            <Icon icon={faEye} link={viewLink} />
          )}
        </div>
      </div>
    </div>
  );
};
export default ProjectDisplay;
