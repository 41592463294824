import React from 'react';
import './styles.scss';
import HamburgerIcon from './HamburgerIcon';
import NavItem from './NavItem';

const NavigationBar = ({ open, setOpen }) => {
  return (
    <nav className='nav'>
      <div className='hamburger-bar'>
        <span className='icon-S-icon-02 logo'></span>
        <HamburgerIcon open={open} setOpen={setOpen} />
      </div>

      <ul className={`nav--inline ${open && 'nav--dropdown'}`}>
        <NavItem
          navText='About'
          exact={true}
          linkTo='/'
          setOpen={setOpen}
          open={open}
        />
        <NavItem
          navText='Skills'
          exact={false}
          linkTo='/skills'
          setOpen={setOpen}
          open={open}
        />
        <NavItem
          navText='Works'
          exact={false}
          linkTo='/works'
          setOpen={setOpen}
          open={open}
        />
      </ul>
    </nav>
  );
};

export default NavigationBar;
