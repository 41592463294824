import React, { useState } from 'react';
import './styles.scss';
import PROJECTS from '../../utils/projects';
import ProjectNameTab from './ProjectNameTab';
import ProjectDisplay from './ProjectDisplay';

const ProjectContainer = () => {
  const projectTitles = Object.keys(PROJECTS);
  const [title, setTitle] = useState(projectTitles[0]);

  return (
    <div className='content__works'>
      <ProjectNameTab
        projectList={projectTitles}
        currentTitle={title}
        setTitle={setTitle}
      />
      <ProjectDisplay title={title} />
    </div>
  );
};

export default ProjectContainer;
