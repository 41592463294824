import React from 'react';
import './styles.scss';
import SkillSection from './SkillSection';

const Skills = () => {
  const frontendList = [
    'HTML5',
    'CSS3',
    'SCSS/Sass',
    'Bootstrap',
    'JavaScript',
    'TypeScript',
    'React',
    'React Native',
    'Angular',
    'Ionic',
    'Git',
  ];
  const backendList = [
    'Node.js',
    'Express',
    'Java',
    'MongoDB',
    'MySQL',
    'Firebase',
  ];
  const designList = ['Adobe Photoshop', 'Adobe Illustrator', 'Adobe XD'];
  const miscList = ['Unity', 'Mathlab', 'Pro/Engineering', 'Microsoft Office'];
  return (
    <div className='content skills'>
      <div className='content--side-margin'>
        <div />
        <div>
          <h1 className='header__title'>What I can do</h1>
          <h2 className='header__subtitle--small'>
            Professional in front-end development with some back-end knowledge
            and design skill.
          </h2>
          <div className='content__skills'>
            <SkillSection
              title='Front-end'
              subtitle='Development'
              list={frontendList}
              column='column-3'
            />
            <SkillSection
              title='Back-end'
              subtitle='Development'
              list={backendList}
              column='column-3'
            />
            <SkillSection
              title='Design'
              subtitle='Tools'
              list={designList}
              column='column-2'
            />
            <SkillSection
              title='Misc'
              subtitle='Softwares'
              list={miscList}
              column='column-2'
            />
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default Skills;
