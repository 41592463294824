import React from 'react';
import { Route, Switch } from 'react-router-dom';
import About from '../components/About/About';
import Skills from '../components/Skills/Skills';
import Works from '../components/Works/Works';
import NotFoundPage from '../components/NotFoundPage/NotFoundPage';

const AppRouter = () => {
  return (
    <Switch>
      <Route path='/' component={About} exact={true} />
      <Route path='/skills' component={Skills} />
      <Route path='/works' component={Works} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default AppRouter;
