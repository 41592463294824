import React from 'react';
import './styles.scss';

const SkillSection = ({ title, subtitle, list, column }) => {
  return (
    <div className='skill'>
      <h3>{title}</h3>
      <h4>{subtitle}</h4>
      <hr />
      <div className={column}>
        {list.map((item) => {
          return <div>{item}</div>;
        })}
      </div>
    </div>
  );
};

export default SkillSection;
