import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className='content'>
      <div className='content--side-margin'>
        <div />
        <div>
          <h1 className='header__title'>
            <span className='red-text'>404</span> Page Not Found
          </h1>
          <Link to='/' className='underline-text'>
            Go to About page
          </Link>
        </div>
        <div />
      </div>
    </div>
  );
};

export default NotFoundPage;
