import React from 'react';
import Icon from './Icon';
import './styles.scss';
import {
  faLinkedinIn,
  faGithub,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

const Icons = () => {
  return (
    <div className='icons'>
      <Icon link='https://github.com/saraluk' icon={faGithub} />
      <Icon link='https://www.linkedin.com/in/saralukkai' icon={faLinkedinIn} />
      <Icon link='https://www.instagram.com/sralu.x' icon={faInstagram} />
    </div>
  );
};

export default Icons;
