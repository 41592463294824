import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.scss';

const Icon = ({ link, icon }) => {
  return (
    <a className='icon' href={link} target='_blank' rel='noopener noreferrer'>
      <FontAwesomeIcon icon={icon} />
    </a>
  );
};

export default Icon;
