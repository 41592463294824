import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import LeftSideBar from './components/LeftSideBar/LeftSideBar';
import NavigationBar from './components/Navigation/NavigationBar';
import AppRouter from './Routers/AppRouter';

import './App.scss';
const App = () => {
  const [open, setOpen] = useState(false);

  return (
    <BrowserRouter>
      <div className='page'>
        <LeftSideBar />
        <div className='page-right'>
          <div className='page-right__nav'>
            <NavigationBar setOpen={setOpen} open={open} />
          </div>
          <div className='page-right__body'>
            <AppRouter />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
