import React from 'react';
import Icons from '../Icon/Icons';
import './styles.scss';

const LeftSideBar = () => {
  return (
    <div className='page-left'>
      <div>
        <span className='icon-S-icon-02'></span>
        <Icons />
      </div>
    </div>
  );
};

export default LeftSideBar;
